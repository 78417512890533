<!-- Hero Start -->
<section class="bg-half-170 d-table w-100  overflow-hidden" id="home" style="padding-bottom: 20px !important; padding-top: 100px;">
   


    <div class="contenedor">
        <img src="assets/images/nutricionista-bilbao/01_Nutricionista_Bilbao.webp"  class="image" alt="Nutricionista Bilbao" loading="lazy">
        <div class="overlay">
          <h2 class="headline">Médicos Bilbao</h2>
          <h1 class="headline">CONSULTA DE NUTRICIÓN EN BILBAO</h1>
        </div>
      </div>
    
   
    <div class="container">
        <div class="row mt-5 align-items-center">
            <div class="col-md-12">
                <div class="title-heading">                    
                    <p class="lead">"Nutrición a tu medida, salud para tu vida"</p><p>
                        Nuestra nutricionista en Bilbao, te ayudará a crear un plan de alimentación ajustado a tu estilo de vida, ayudándote a vivir con enfermedades crónicas, mejorar tu rendimiento físico y, sobre todo, sentirte bien contigo mismo.
                        </p>
                    
                        <H2 class="text-center">NUTRICIONISTA EN BILBAO</H2>
                    <p>En <strong>MÉDICOS BILBAO</strong> tenemos tu especialista en dietética y nutrición en <strong>Bilbao</strong>. 
                        Nuestra misión es ayudarte a alcanzar tus objetivos de salud y bienestar a través de una alimentación equilibrada y personalizada.</p>
                        <p>Itsaso Martín, nuestra dietista - nutricionista con más de 10 años de experiencia, te ofrece asesoramiento nutricional totalmente personalizado para mejorar tu calidad de vida, tengas la edad que tengas y sea cual sea tu condición física y de salud.</p>
                        
                            <p>    Nuestra dietista en Bilbao te ofrecerá múltiples recursos, desde planes de alimentación adaptados a tus necesidades hasta consejos prácticos para llevar una vida saludable. Estamos para guiarte en cada paso de tu camino hacia una mejor salud.</p>
                        
                                <p class="text-center">   <b>     ¡Juntos, podemos lograr tus metas nutricionales!<br>
                        Contacta con tu nutricionista en Bilbao hoy y comienza tu viaje hacia una vida más saludable.</b>
                        </p>    
                </div>
            </div>

           
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section bg-light" style="padding-top: 20px !important; padding-bottom:20px !important;">
    <div class="container">
        <div class="row">           

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                    
                    <div class="card-body p-0 content">
                        <h5>Centro <br> Médicos Bilbao</h5>

                        <a href="https://medicosbilbao.com/"  target="_blank" class="btn btn-pills btn-soft-success mt-4">Ir a la web</a>         
                        
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Nuestra <br>Nutricionista</h5>                        

                        <a href="https://medicosbilbao.com/quienes-somos/itsaso-martin-nutricionista-bilbao" target="_blank" class="btn btn-pills btn-soft-success mt-4">Itsaso Martín</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                    
                    <div class="card-body p-0 content">
                        <h5>Dónde<br> Estamos</h5>
                              <a href="https://medicosbilbao.com/contactar" target="_blank" class="btn btn-pills btn-soft-success mt-4">Ver Mapa</a>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-3 col-md-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                   
                    <div class="card-body p-0 content">
                        <h5>Reserva <br>Cita</h5>                        

                            <a href="https://medicosbilbao.com/contactar" class="btn btn-pills btn-soft-success mt-4" data-bs-toggle="modal"
                            data-bs-target="#appointment-request">Ir a contactar</a>

                       
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<section class="section" style="padding-top: 20px ; padding-bottom:20px ;">

    <div class="container ">
        <h4 class="title mb-4 text-center">NUESTRA CONSULTA DE NUTRICIÓN EN BILBAO</h4>

        <p class="text-center lead">Nuestra <strong>nutricionista en Bilbao</strong> te propondrá un plan nutricional integral totalmente personalizado y adaptado a tus necesidades físicas, para lograr que mantengas una dieta adecuada y equilibrada que te ayude a lograr un estado de salud óptimo.</p>
        <p>
            Nuestra <strong>consulta de nutrición en Bilbao</strong> está situada en Gran Vía Don Diego López de Haro 81, 3ª planta, en el centro de Especialistas Médicos Bilbao. Nos encontramos muy cerca del metro, tranvía, autobús, parada de taxi y aparcamiento público, con lo que podrás llegar a nuestra consulta muy fácilmente.         </p>
      
                          <div class="row">
                              <div class="col-lg-6 order-2 order-lg-1 p-0">
                                  <div class="h-100 m-0">
                                      <div class="row m-0">
                                          <div class="col-half-section col-half-section-right py-2 ms-auto">
                                              <div class="p-3">
                                                  <h3 class="font-weight-semibold ls-0 text-color-default text-4 mb-0">Nutricionista en Bilbao</h3>
                                                  
                                                  <p class="text-3-5 pb-2 text-justify"      >
                                                      <p>En la <strong>consulta de nutrición de Médicos Bilbao</strong>, te ayudaremos a mejorar tu salud mediante planes alimentarios personalizados y variados.
                                                        Tu nutricionista en Bilbao te ofrecerá planes nutricionales adaptados a tus necesidades para ayudarte frente a enfermedades crónicas como diabetes, hipertensión y trastornos digestivos.</p>
                                                        
                                                        
                                                        <p>A través de evaluaciones detalladas, seguimiento continuo y educación nutricional, tu nutricionista en Bilbao te ayudará a mejorar tu bienestar, prevenir futuras enfermedades y mejorar tu calidad de vida con una alimentación adecuada y balanceada.
                                                        
                                                        </p>                    
                                                  
                                                  <a href="https://medicosbilbao.com/especialidades/fisioterapeuta-bilbao" class="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="600" data-plugin-options="{'minWindowWidth': 0}">Más Info</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-6 order-1 order-lg-2 p-0">
                                  
                                  <img src="assets/images/nutricionista-bilbao/02_Nutricionista_Bilbao.webp" class="img-fluid" alt="Nutricionista en Bilbao" loading="lazy">
                                 
                              </div>
                          </div>	
      
                          <div class="row" >                    
                              <div class="col-lg-6 p-0">
                                 
                                  <img src="assets/images/nutricionista-bilbao/03_Nutricionista_Deportiva_Bilbao.webp" class="img-fluid" alt="Nutricionista Deportivo en Bilbao" loading="lazy">
      
                              </div>
                              <div class="col-lg-6 p-0">
                                  <div class="h-100 m-0">
                                      <div class="row m-0">
                                          <div class="col-half-section col-half-section-left py-2">
                                              <div class="p-3">
                                                  <h3 class="font-weight-semibold ls-0 text-color-default text-4 mb-0   ">Nutricionista Deportiva en Bilbao</h3>
                                                  
                                                  <p class="text-3-5 pb-2 text-justify"> Como nutricionista para deportistas en Bilbao, Itsaso Martín diseña planes alimentarios personalizados individuales o en grupo para optimizar tu rendimiento y recuperación. </p>
                                                  <p>                          Ya seas un deportista amateur, senior o profesional, nuestro nutricionista te ayudará a potenciar tu energía, resistencia y fuerza. Con seguimiento continuo y ajustes según tus objetivos, trabajamos juntos para mejorar tu desempeño deportivo, prevenir lesiones y promover tu bienestar general.
                                                    
                                                    </p>
                  
                                                  <a href="https://medicosbilbao.com/especialidades/fisioterapeuta-deportivo-bilbao" class="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light appear-animation" data-appear-animation="fadeInUpShorterPlus" data-appear-animation-delay="600" data-plugin-options="{'minWindowWidth': 0}">Más Info</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>     
                      </div>
    

    <div class="container mt-20">
        <div class="row align-items-end mb-4 pb-2">
            <div class="col-md-10">
                <div class="section-title  text-md-start">
                    
                  
                   <h2 class="text-center ">CÓMO TE AYUDAMOS EN NUESTRA                     CONSULTA DE NUTRICIÓN EN BILBAO
                    </h2>

                 
                </div>
            </div>
            <!--end col-->

           
            <!--end col-->
        </div>
        <!--end row-->
 
        <div class="row"> 
            <div >
                <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                    class="nav nav-pills rounded shadow p-3 mb-0 col-md-12 list-inline  list-inline-columns" orientation="horizontal">
                <li ngbNavItem="cronico" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Enfermedades Crónicas</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/nutricionista-bilbao/04_Nutricion_Bilbao_Cronicos.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Enfermedades Crónicas"> 
                                <div class="mt-4">
                                    <h5>Nutrición Enfermedades Crónicas</h5>                                    

                                        <p class="text-muted my-3">

                                            
 
                                            Tu nutricionista en Bilbao te diseñará planes nutricionales adaptados y variados que te ayuden a controlar condiciones como diabetes, hipertensión y enfermedades cardiovasculares, optimizando así la salud a largo plazo.
                                        
                                        </p>                  
                                </div>
                            </div>
                        </ng-template>
                    </li>   

                    <li ngbNavItem="articulaciones" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Perdida de peso saludable</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/nutricionista-bilbao/05_Nutricionista_Bilbao_Adelgazar.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Perdida de peso saludable"> 
                                <div class="mt-4">
                                    <h5>Perdida de peso saludable</h5>
                                                                            

                                        <p class="text-muted my-3">
                                            Consulta a tu dietista en Bilbao para descubrir estrategias nutricionales adaptadas a tus necesidades individuales y logar una reducción de peso saludable y sostenible. Esto incluye la educación sobre porciones adecuadas, selección de alimentos nutritivos y prácticas alimentarias que fomenten la pérdida de grasa mientras se preserva la masa muscular.
                                       </p>

                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>   

                    <li ngbNavItem="musculares" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Intolerancias alimentarias</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/nutricionista-bilbao/06_Nutricionista_Bilbao_Alergias.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Alergias e intolerancias alimentarias"> 
                                <div class="mt-4">
                                    <h5>Alergias e intolerancias alimentarias</h5>
                                    <p class="text-muted my-3">Tu nutricionista en Bilbao te ayudará a identificar y gestionar alergias alimentarias, como alergias al trigo o a los frutos secos, e intolerancias alimentarias, como la intolerancia a la lactosa. 
                                        También te proporcionará orientación sobre cómo evitar los alimentos desencadenantes y garantizar una dieta equilibrada que cumpla con los requisitos nutricionales sin comprometer tu salud.
                                        </p>

                                </div>
                            </div>
                        </ng-template>
                    </li>   
                    
                    
                    <li ngbNavItem="top" class="nav-item list-inline-item col-6 col-md-4">                        
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Estado de ánimo</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/nutricionista-bilbao/07_Nutricionista_Bilbao_estado_animico.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Estado de ánimo">
                                <div class="mt-4">
                                    <h5>Mejora tu estado físico y anímico</h5>
                                    <p class="text-muted my-3">Hay alimentos y nutrientes que pueden influir positivamente en el estado de ánimo y la función cognitiva, como omega-3, vitaminas del complejo B y antioxidantes. Tu nutricionista en Bilbao te ayudará a mejorar el bienestar emocional y mental mediante una dieta equilibrada que facilite mayor bienestar y la respuesta adecuada al estrés.</p>
                                        
                             
                                     
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    
                    <li ngbNavItem="middle" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Nutrición etapas vida</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/nutricionista-bilbao/08_Nutricionista_Bilbao_Embarazo.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Nutrición etapas vida">
                                <div class="mt-4">
                                    <h5>Nutrición en las distintas etapas de la vida</h5>
                                   

                                    <p class="text-muted my-3">


                                        
                                        Tu nutricionista en Bilbao te ofrecerá orientación nutricional específica para diferentes etapas de la vida, como el embarazo y la lactancia, la infancia y la adolescencia, la menopausia y para adultos mayores. Asegurando que las necesidades nutricionales únicas de cada grupo se satisfagan adecuadamente en cada etapa vital y para promover una forma de vida saludable.
                                   

                                    </p>                       
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="bottom" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Nutrición Infantil</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow  show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/nutricionista-bilbao/09_Nutricionista_infantil_Bilbao.webp" class="rounded shadow"  style="width:100%" loading="lazy" alt="Nutrición Infantil">
                                <div class="mt-4">
                                    <h5>Nutrición Infantil</h5>
                                    
                                        <p class="text-muted my-3">	Como nutricionista infantil en Bilbao, te proporcionaré orientación nutricional específica para bebés, niños y adolescentes, asegurando que obtengan los nutrientes necesarios para un crecimiento y desarrollo saludables. Es importante también concienciar a los padres sobre la introducción de alimentos sólidos, la importancia de una alimentación balanceada y la prevención de problemas nutricionales comunes en la infancia, como la obesidad infantil y las deficiencias nutricionales.</p>                                
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="general" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Prevención enfermedades</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/nutricionista-bilbao/10_Nutricionista_Bilbao_Saludable.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Prevención enfermedades">
                                <div class="mt-4">
                                    <h5>Prevención enfermedades</h5>
                                    
                                                                        
                                        <p class="text-muted my-3">

                                            
                                            Nuestra dietista-nutricionista en Bilbao te asesorará sobre la relación entre la dieta y el riesgo de desarrollar enfermedades crónicas como la obesidad, la diabetes tipo 2 y las enfermedades cardiovasculares. 
                                       Te facilitará planes nutricionales preventivas, como la reducción del consumo de grasas saturadas y azúcares añadidos, y fomenta la ingesta de alimentos ricos en fibra, vitaminas y minerales para apoyar la salud general a largo plazo.
                                       

                                        </p>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="medicine" class="nav-item  list-inline-item col-6 col-md-4">
                        <a ngbNavLink>
                            <div class="text-start py-1 px-2">
                                <h6 class="mb-0">Rendimiento Deportivo</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade p-4 rounded shadow active show" id="dental"
                                role="tabpanel" aria-labelledby="fisio">
                                <img src="assets/images/nutricionista-bilbao/11_Nutricionista_Deportiva_Bilbao.webp" class="rounded shadow" style="width:100%" loading="lazy" alt="Rendimiento Deportivo"> 
                                <div class="mt-4">
                                    <h5>Mejora del Rendimiento Deportivo</h5>
                                    
                                    <p class="text-muted my-3">

                                        MEJORA DEL RENDIMIENTO DEPORTIVO 	Nuestra nutricionista deportivo en Bilbao trabaja con atletas y deportistas de todos los niveles para maximizar su rendimiento físico y recuperación de lesiones, tanto a nivel individual como en grupo. Esto implica proporcionar planes nutricionales que optimicen la energía, mejoren la resistencia y aceleren la recuperación muscular mediante la adecuada ingesta de nutrientes antes, durante y después del ejercicio.

                                    </p>                                      
                                </div>
                            </div>
                        </ng-template>
                    </li>                    

                    
                </ul>
                <div [ngbNavOutlet]="nav" class=" mt-2 col-md-12"></div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!-- End -->
<!-- Start -->
<!--end section-->
<section class="section" style="padding-top: 20px ; padding-bottom:20px ;">

    <div class="container my-2">

        <h2 class="text-center">Nuestro <strong>Centro médico en Bilbao</strong> cuenta además con otras especialidades médicas</h2>

        <div class="row row-cols-2 row-cols-md-2 row-cols-lg-4  g-4">
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/nutricionista-bilbao/11_Link_Oftalmologia_bilbao.webp" class="card-img-top" alt="Oftalmología Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/oftalmologo-bilbao" target="_blank">Oftalmología Bilbao</a></h5>
                 </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/nutricionista-bilbao/13_Link_fisioterapia_bilbao.webp" class="card-img-top" alt="Fisioterapia Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/fisioterapeuta-bilbao" target="_blank">Fisioterapia Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/nutricionista-bilbao/Portada_Logopeda_bilba.webp" class="card-img-top" alt="Logopedia Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/logopeda-bilbao" target="_blank">Logopedia Bilbao</a></h5>
                
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card h-100">
              <img src="assets/images/nutricionista-bilbao/12_Link_Psicologia_bilbao.webp" class="card-img-top" alt="Psicología Bilbao" loading="lazy">
              <div class="card-body text-center">
                <h5 class="card-title"><a href="https://medicosbilbao.com/especialidades/psicologo-bilbao" target="_blank">Psicología Bilbao</a></h5>                
              </div>
            </div>
          </div>
        </div>
      </div>



    </section>
<!--end section-->


<!-- End -->

<!-- Start -->
<div class="container-fluid px-0">
    <div class="py-5 bg-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7 ">
                    <div class="section-title bg-white text-dark rounded border p-2 mb-3">
                        <div class="d-flex">
                            <a class="logo" routerLink="/index">
                                <img src="assets/images/logo_nutricion_bilbao.png" class="l-dark logo-light-mode" class="img-fluid" alt="Nutricionista Bilbao" loading="lazy">        
                              </a>
                            <div class="flex-1 ms-md-4 ms-3">
                                <h4 class="fw-bold text-dark mb-1">Nutricionista Bilbao</h4>
                                <p class="text-dark-50 mb-0">Nutrición a tu medida, salud para tu vida</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-5 mt-4 mt-sm-0">
                    <div class="text-md-end ms-5 ms-sm-0">
                        <a href="https://medicosbilbao.com" class="btn btn-primary me-2 me-lg-2 me-md-0 my-2"
                            data-bs-toggle="modal" data-bs-target="#appointment-request"><i
                                class="uil uil-file-alt"></i> Descubre más sobre Médicos Bilbao</a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </div>
    <!--end div-->
</div>

<!--modal contactar -->  
<div class="container">
	<div class="row g-0" >		
    <div class="nb-form">
    <p class="title"><strong>Pide tu cita aquí</strong></p>
    <img src="https://medicosbilbao.com/img/logocuadrado.png" alt="Cita Logopeda  Bilbao" class="user-icon" >
    <p class="message" ><b>Médicos Bilbao: </b>Infórmate sin compromiso. Puedes contactar con nosotros a través de estos enlaces </p>	
	<ul class="list-group">
  <li class="list-group-item"><i class="fab fa-whatsapp"></i> <a href="https://api.whatsapp.com/send?phone=+606573238&text=Hola,%20estoy%20interesado%20en%20pedir%20una%20cita%20...%20" target="_blank"> 606 57 32 38</a><br>(solo whatsapp) </li>
  <li class="list-group-item"><i class="fas fa-phone"></i> <a href="tel:+34944648357" style="text-decoration:none"> 944 648 357</a></li>
  <li class="list-group-item"><i class="fas fa-globe"></i><a href="https://www.doctoralia.es/clinicas/especialistas-medicos-bilbao" rel="nofollow" target="_blank" class="reserva"> Reservar cita</a></li>  
  <li class="list-group-item"><i class="fa fa-envelope" aria-hidden="true"></i> <a href="https://medicosbilbao.com/contactar" id="correo"> Correo eléctrónico</a></li>
  </ul>    
  </div>
	</div>
</div>	
<!-- FIN modal contactar -->  
<!--end container-->
<!-- End -->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->